<template>
  <div class="grid__col flex fww">
    <label class="grid__input--label" for="user_new_pass">
      {{ settings && settings.user_new_pass ? settings.user_new_pass : 'New password' }}
    </label>
    <span class="mla pointer" @click="generatePassword">Ask us</span>
    <div class="grid__field">
      <input
        minlength="12"
        class="grid__input"
        id="user_new_pass"
        name="user_new_pass"
        v-model="user_new_pass"
        :type="pass_new_type"
        :class="pass_is_different ? 'is-invalid' : ''"
        :required="!user || !user.profile_is_filled"
        :placeholder="settings && settings.user_new_pass ? settings.user_new_pass : 'New password'"
      >
      <span class="grid__icon" @click="switchVisibility('pass_new_type')">
        <i class="icon icon--eye" :class="pass_new_type === 'password' ? 'icon--eye' : 'icon--eye-close'"></i>
      </span>
    </div>
  </div>
  <div class="grid__col flex fww">
    <label class="grid__input--label" for="user_new_pass_confirm">
      {{ settings && settings.user_new_pass_confirm ? settings.user_new_pass_confirm : 'Repeat new password' }}
    </label>
    <router-link v-if="settings && settings.rememberLink" class="mla pointer" :to="{name: 'Login'}">
      I remember the password
    </router-link>
    <div class="grid__field">
      <input
        minlength="12"
        class="grid__input"
        id="user_new_pass_confirm"
        name="user_new_pass_confirm"
        v-model="user_new_pass_confirm"
        :type="pass_new_confirm_type"
        :class="pass_is_different ? 'is-invalid' : ''"
        :required="!user || !user.profile_is_filled"
        :placeholder="settings && settings.user_new_pass_confirm ? settings.user_new_pass_confirm : 'Repeat new password'"
      >
      <span class="grid__icon" @click="switchVisibility('pass_new_confirm_type')">
        <i class="icon icon--eye" :class="pass_new_confirm_type === 'password' ? 'icon--eye' : 'icon--eye-close'"></i>
      </span>
    </div>
    <p class="grid__error">{{ pass_is_different ? 'Password and Confirm Password is different' : '' }}</p>
  </div>
  <div class="grid__col">
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'PasswordSetter',
  props: {
    settings: {
      type: Object,
    }
  },
  created() {
    this.user = this.getInitialSettings ? this.getInitialSettings.user : null;
  },
  computed: {
    ...mapGetters(
      {
        getInitialSettings: 'user/getInitialSettings',
      }
    ),
  },
  data() {
    return {
      user: null,
      user_new_pass: null,
      user_new_pass_confirm: null,
      pass_new_type: 'password',
      pass_new_confirm_type: 'password',
      pass_is_different: null,
      timeoutID: null,
    };
  },
  emits: [ 'update-password' ],
  methods: {
    switchVisibility(type) {
      this[type] = this[type] === 'password' ? 'text' : 'password';
    },
    generatePassword( e, length = 16, special_chars = true ) {
      this.pass_new_type = this.pass_new_confirm_type = 'text';

      clearTimeout( this.timeoutID );
      this.timeoutID = setTimeout( () => {
        this.pass_new_type = this.pass_new_confirm_type = 'password';
      }, 3000 );

      let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      if( special_chars ) {
        charset += '!@#$%^&*()';
      }

      let password = '';
      if( window.crypto && window.crypto.getRandomValues ) {
        password = Array(length)
            .fill(charset)
            .map(x => x[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * (x.length + 1))])
            .join('');
      } else {
        for ( let i = 0, n = charset.length; i < length; ++i ) {
          password += charset.charAt( Math.floor( Math.random() * n ) );
        }
      }
      this.user_new_pass = this.user_new_pass_confirm = password;
      this.pass_is_different = false;
    },
  },
  watch: {
    user_new_pass(val) {
      this.pass_is_different = this.user_new_pass !== this.user_new_pass_confirm;
      this.$emit('update-password', {
        user_new_pass: this.user_new_pass,
        user_new_pass_confirm: this.user_new_pass_confirm,
        pass_is_different: this.pass_is_different,
      });
    },
    user_new_pass_confirm(val) {
      this.pass_is_different = this.user_new_pass !== this.user_new_pass_confirm;
      this.$emit('update-password', {
        user_new_pass: this.user_new_pass,
        user_new_pass_confirm: this.user_new_pass_confirm,
        pass_is_different: this.pass_is_different,
      });
    },
  },
}
</script>

<style lang="postcss">
@import "styles.css";
</style>